<template>
	<div
		v-if="is_on_pin"
		class="position-fixed-full z-index-layer"
	>
		<div class="container full-height flex-column justify-space-between text-center">
			<div class="bg-identify pa-10">
				<h5>{{ program.name }}</h5>
			</div>
			<div>

				<div class="">
					<h4 v-if="pin_type == 'set'">보안을 위해 PIN을 설정하세요.</h4>
					<h4 v-else>보안 PIN 입력</h4>
				</div>

				<template
					v-if="false"
				>
					<div>{{ pin }}</div>
					<div>{{ pin_confirm}}</div>
					<div>{{ step }}</div>
					<div>{{ step_confirm }}</div>
				</template>

				<div class="mt-30 justify-space-between gap-10 pa-10">

					<div
						v-for="(item, index) in pinForType"
						:key="'pin_' + index"
						class="flex-1 pa-20"
						:class="item ? 'bg-blue' : 'bg-gray'"
					></div>
				</div>
				<div class="size-px-14 mt-10">

					<p v-if="pin_type == 'check'">보안 PIN번호 6자리를 입력하세요</p>
					<template
						v-else
					>
						<p v-if="is_confirm">PIN번호를 한번 더 입력하세요.</p>
						<p v-else>신규 PIN번호를 입력하세요.</p>
					</template>
				</div>
			</div>

			<div class="bg-identify">
				<table class="table table-pin bg-identify-table ">
					<tbody>
						<tr
							v-for="num in 3"
							:key="'num_' + num"
						>
							<td
								v-for="num_s in 3"
								:key="'num_' + num + '_' + num_s"
								@click="setPin(num_s + (3 * (num - 1)))"
								class="cursor-pointer"
							>
								<button type="button" class="key">
									<span>{{ num_s + (3 * (num - 1)) }}</span>
								</button>
							</td>
						</tr>
						<tr>
							<td
								class="cursor-pointer"
							>
								<button
									v-if="is_can_cancel"
									type="button"
									@click="cancel"
								>
									<span class="cancel size-px-16">취소</span>
								</button>
								<button
									v-else-if="is_confirm"
									type="button"
									@click="reset"
								>
									<v-icon class="color-white">mdi-refresh</v-icon>
								</button>
							</td>
							<td
								class="cursor-pointer"
								@click="setPin(0)"
							>
								<button type="button">
									<span>0</span>
								</button>
							</td>
							<td
								class="cursor-pointer"
								@click="back()"
							>
								<button type="button">
									<v-icon class="color-white">mdi-backspace</v-icon>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>


<script>

export default {
	name: 'Pin'
	, props: ['user']
	, components: {  }
	, data: function(){
		return {
			program: {
				name: 'PIN 보안'
			}
			, step: 0
			, step_confirm: 0
			, max: 5
			, pin: [false, false, false, false, false, false]
			, pin_confirm: [false, false, false, false, false, false]
			, is_confirm: false
			, is_otp: false
			, is_on_pin: false
			, pin_option: {

			}
		}
	}
	,computed: {

		pinForType: function(){

			if(this.is_confirm){
				return this.pin_confirm
			}else{
				return this.pin
			}
		}
		, pin_type: function(){
			return this.pin_option.type
		}
		, is_can_cancel: function(){
			let t = false
			if(this.pin_type != 'new'){
				t = true
			}
			return t
		}
		, is_change: function(){
			let t = false

			//if(this.pin_type == 'check' || this.pin_type == 'pin_login'){
			if(this.pin_type == 'pin_login'){
				t = true
			}

			return t
		}
	}
	,methods: {

		setPin: function(number){

			if(this.is_confirm){
				if (!this.pin_confirm[this.step_confirm]) {
					this.$set(this.pin_confirm, this.step_confirm, number + '')
					this.step_confirm++
				}
			}else {
				if (!this.pin[this.step]) {
					this.$set(this.pin, this.step, number + '')
					this.step++
				}
			}
		}
		,back: function(){
			if(this.is_confirm) {
				this.step_confirm--
				this.$set(this.pin_confirm, this.step_confirm, false)
			}else {
				this.step--
				this.$set(this.pin, this.step, false)
			}
		}
		, reset: function(){
			this.is_confirm = false
			this.step = 0
			this.step_confirm = 0
			this.pin = [false, false, false, false, false, false]
			this.pin_confirm = [false, false, false, false, false, false]
		}
		,clear: function() {
			if(this.is_confirm){
				this.pin_confirm = [false, false, false, false, false, false]
				this.step_confirm = 0
			}else{
				this.pin = [false, false, false, false, false, false]
				this.step = 0
			}
		}
		, cancel: function(){
			this.reset()
			this.is_on_pin = false
			this.$bus.$emit('pinCancel')
		}
		, pinCallback: function(){
			this.$bus.$emit('pinCallback', {
				pin: this.pin.toString().replaceAll(',', '')
				, pin_confirm: this.pin_confirm.toString().replaceAll(',', '')
				, option: this.pin_option
			})
			this.reset()
		}
		, postPinCheck: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin:  this.pin.toString().replaceAll(',', '')
					}
				})
				if(result.success){
					this.pinCallback()
					this.is_on_pin = false
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.reset()
			}
		}
	}
	,created() {
		this.$bus.$off('onPin')
		this.$bus.$on('onPin', (option) => {

			if(this.user.is_pin > 0){

				this.is_on_pin = true
				this.pin_option = option
			}else{

				switch (option.type){
					case 'new':
						this.is_on_pin = true
						this.pin_option = option
					break;
					default:
						this.$bus.$emit('notify', { type: 'error', message: 'PIN번호가 설정되지 않았습니다.'})
						this.$bus.$emit('to', { name: 'MyPage' })
					break;
				}
			}
		})
		this.$bus.$on('offPin', () => {
			this.is_on_pin = false
			this.pin_option = {}
		})
	}
	,watch: {
		step: {
			handler: function(call){
				if(call > this.max){
					this.step = this.max
				}
				if(call < 0){
					this.step = 0
				}
			}
		}
		,step_confirm: {
			handler: function(call){
				if(call > this.max){
					this.step_confirm = this.max
				}
				if(call < 0){
					this.step_confirm = 0
				}
			}
		}
		,pin: {
			deep: true
			,handler: function(call){
				if(this.pin_type == 'new' || this.pin_type == 'update'){

					let is_set = false
					call.forEach(function(val){
						if(val === false){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						this.is_confirm = true
					}

				}else if(this.pin_type == 'check'){
					let is_set = false
					call.forEach(function(val){
						// console.log(val + ' : ' + typeof val)
						if(val === false){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						this.postPinCheck()
					}
				}
			}
		}
		,pin_confirm: {
			deep: true
			,handler: function(call){

				if(this.is_confirm){

					let is_set = false
					call.forEach(function(val){
						if(!val){
							is_set = false
							return false
						}else{
							is_set = true
						}
					})

					if(is_set) {
						if(this.pin.toString() == this.pin_confirm.toString()){
							this.pinCallback();
						}else{
							this.$bus.$emit('notify',  { type: 'error', message: 'PIN 번호가 맞지 않습니다. 다시 설정해주세요'})
							this.clear()
						}
					}
				}
			}
		}
	}
}
</script>
