<template>
	<Layout
		:metaInfo="metaInfo"
		:Base64="Base64"
		:codes="codes"
		:user="user"
		:seller_info="seller_info"
	/>
</template>

<script>

import { metaInfo } from '@/resources/config/metainfo'
import { Base64 } from 'js-base64'
import {codes} from "@/resources/config/codes";
import Layout from '@/view/Layout/Layout.vue'

export default {

	name: 'App'
	,metaInfo: metaInfo
	,components: { Layout }
	,data: () => ({
		Base64: Base64
		, metaInfo: metaInfo
		, TOKEN: ''
		, codes: codes
		, user: {

		}
		, seller_info: {

		}
	})
	,methods: {
		isAuth: async function(){
			// console.log('isAuth start !!')
			let TOKEN = sessionStorage.getItem(process.env.VUE_APP_NAME + 'T')
			let skip = false
			let except = ['auth']
			let path = document.location.href
			except.forEach(function (val) {
				if (path.toLowerCase().indexOf(val) > -1) {
					skip = true
					return false
				}
			})
			if(skip){
				console.log('isAuth skip ! do next !!')
			}else {
				if (!TOKEN || TOKEN === 'false') {
					console.log('not auth ! to login !!')
					this.toLogin()
				}else{
					await this.getBaseInfo()

				}
			}

			// console.log('isAuth finished !!')
		}
		, toLogin: function(){
			sessionStorage.clear()

			this.to( { name: 'Login', type: 'href'})
		}
		, getBaseInfo: async function(){
			try{
				const result = await this.$Axios({
					method: 'get'
					,url: 'payment/getBaseInfo'
					,data: {
					}
				})

				if(result.success){
					this.user = result.data
					this.seller_info = this.user.shop_info
					this.setCode(this.user.code_list)
					this.$set(this.user, 'is_sms', sessionStorage.getItem('is_sms'))
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,getBaseCode: async function(){
			try{
				const result = await this.$Axios({
					method: 'get'
					,url: 'management/getCodeList'
					,data: {
					}
				})

				if(result.success){
					this.setCode(result.data.result)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.toLogin()
			}
		}
		,setCode: function(code_list){
			let list = {}
			code_list.forEach(function(code){
				let main = list[code.main_code]
				if(!main){
					code.items = []
					list[code.main_code] = code
				}
				list[code.main_code].items.push(code)
			})

			Object.assign(list, this.codes)
			this.$set(this, 'codes', list)
		}
		, to: async function ({name, path, params, query = {}, not_query, hash, type}) {
			// console.log('to params', name, this.$route.name)

			query.a = Math.random()

			let router = this.$router.resolve({ name: name, path: path, params: params, query: query, hash: hash ? '#' + hash : ''})
			console.log('router', router)
			if (!not_query) {
				localStorage.removeItem('query')
				this.query = {}
			} else {
				this.setQuery()
			}

			if(type == 'href'){
				document.location.href = router.href
			}else{
				await this.$router.push({ path: router.href })
			}
		}
	}
	,created() {
		this.isAuth()

		this.$bus.$on('to', ({ name, path, params, query, not_query, hash, type}) => {
			this.to({ name, path, params, query, not_query, hash, type})
		})

		this.$bus.$on('addUserData', ({key, val}) => {
			this.$set(this.user, key, val)
		})

		this.$bus.$on('updateUser', (data) => {
			for(let [key, value] of Object.entries(data)){
				this.$set(this.user, key, value)
			}

// console.log('updateUser', this.user)
		})

		this.$bus.$on('shop_info', (shop_info) => {
			this.seller_info = shop_info
		})
	}
};
</script>
