<template>
	<div
		class="side"
	>
		<div
			class="bg-side"
			@click="toggleSide"
		></div>

		<div
			class="side-container flex-column overflow-hidden"
		>
			<div
				class="logo-position text-center " style="flex-basis: 90px"
			>
				<h3
					class="pa-20 color-white"
				>Wallet</h3>
			</div>
			<div
				class="flex-row justify-space-between under-line pa-10"
			>
				<span class="">
					{{ user.account_name }} 님
				</span>

				<v-icon
					@click="toggleSide"
					class=""
				>mdi mdi-arrow-left-bold-box-outline</v-icon>
			</div>

			<div class="">
				<ul class="">
					<li
						v-if="user.sms_auth_uid"
						class="pa-10 under-line flex-row justify-space-between items-center"
					>SMS 인증 <div class="label label-success">인증 완료</div>
					</li>
					<li
						v-else
						class="pa-10 under-line flex-row justify-space-between items-center"
						@click="toSmsConfirm"
					>SMS 인증 <div class="label label-danger">미인증</div>
					</li>
					<li
						v-if="user.is_pin > 0"
						class="pa-10 under-line flex-row justify-space-between items-center"
					>PIN 설정 <div class="label label-success">설정 완료</div>
					<li
						v-else
						class="pa-10 under-line flex-row justify-space-between items-center"
						@click="toPin"
					>PIN 설정 <div class="label label-danger">미설정</div>
					</li>
					<li
						v-for="(item, index) in list_menu"
						:key="'item_' + index"
						class="pa-10 under-line flex-row justify-space-between cursor-pointer"
						@click="toDetail(item)"
					>
						<div>{{  item.name }}</div>
						<button
						><v-icon>mdi-chevron-right</v-icon></button>
					</li>
				</ul>
			</div>

			<div class="mt-auto">
				<button
					class="btn btn-primary"
					@click="logout"
				>로그아웃</button>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'Side'
		,props: ['user']
		,data: function(){
			return {
				items: [
					{ name: '가맹점', to: 'Franchisee', type: 'distributor'}
					, { name: '마이페이지', to: 'MyPage'}
				]
			}
		}
		, computed: {
			list_menu: function(){
				let t = []
				this.items.filter( (item) => {
					if(!item.type || item.type == this.user.agency_type){
						t.push(item)
					}
				})
				return t
			}
		}
		,methods: {
			toggleSide: function(){
				this.$emit('toggleSide')
			}
			, logout: function(){
				let auto_login = localStorage.getItem(process.env.VUE_APP_NAME + 'A')

				if(auto_login) {

					if(confirm('로그아웃시 자동로그인도 해제됩니다. 로그아웃 하시겠습니까?')) {

						localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
						localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

						sessionStorage.clear()

						this.$bus.$emit('to', { name: 'Login', type: 'href'})
					}

				}else{

					if(confirm('로그아웃 하시겠습니까?')){
						sessionStorage.clear()
						this.$bus.$emit('to', { name: 'Login', type: 'href'})
					}
				}
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: item.to})
				this.toggleSide()
			}
			, toSmsConfirm: function(){
				this.$bus.$emit('to', { name: 'SmsConfirmRequest'})
				this.toggleSide()
			}
			, toPin: function(){

			}
		}
		,created: function(){
		}
	}
</script>

<style>

	.side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
	}
	.bg-side {
		width: 100%; height: 100%;
		position: fixed; left: 0; top: 0; z-index: 98;
		background-color: gray;
		opacity: 0.5;
	}
	.side-container{
		width: 80%; height: 100%;
		max-width: 390px;
		position: relative;  z-index: 99;
		background-color: white;
		overflow-y: auto;
	}

	.side-container {

	}

	.li-side-content {
		padding: 10px 10px;
		border-bottom: 1px solid #ddd;
	}
	.li-side-depth-1 {
		text-indent: 0px;
	}
	.li-side-depth-2 {
		text-indent: 10px;
	}
	.li-side-depth-3 {
		text-indent: 20px;
	}
	.li-side-depth-4 {
		text-indent: 30px;
	}

	dt, dd { display: inline-block}
</style>