<template>
	<div
		class="bottom justify-space-between text-center box-shadow-top position-relative bg-base position-relative"
	>
		<!-- 홈 --> 
		<button
			class="flex-1 ptb-10 "
			:class="{on: is_on_home}"
			@click="toHome"
		>
			<v-icon
				class="color-base"
			>mdi mdi-home-outline</v-icon>
		</button>

		<!-- 결제 내역 -->
		<button
			v-if="is_payment"
			class="flex-1 ptb-10 "
			@click="toPaymentHistory"
			:class="{on: is_on_payment}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-file-document</v-icon>
		</button>

		<!-- 지갑 입출금 내역 -->
		<button
			v-if="is_wallet"
			class="flex-1 ptb-10 "
			@click="toWalletHistory"
			:class="{on: is_on_wallet}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-wallet</v-icon>
		</button>

		<!-- 지갑 정산 내역 -->
		<button
			v-if="is_wallet"
			class="flex-1 ptb-10 "
			@click="toCalculate"
			:class="{on: is_on_calculate}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-calculator</v-icon>
		</button>
		
		<button
			class="flex-1 ptb-10 "
			@click="toAccount"
			:class="{on: is_on_account}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-account</v-icon>
		</button>
	</div>
</template>

<script>
export default{
	name: 'BottomPayment'
	,props: ['user', 'cart_cnt']
	,data: function(){
		return {
		}
	}
	,computed: {
		is_on_home: function(){
			if(this.$route.path == '/Index'){
				return true
			}else{
				return false
			}
		}
		, is_on_payment: function(){
			if(this.$route.path.indexOf('/Payment') > -1){
				return true
			}else{
				return false
			}
		}
		, is_on_wallet: function(){
			if(this.$route.path.indexOf('/Wallet') > -1){
				return true
			}else{
				return false
			}
		}
		,is_on_calculate: function(){
			if(this.$route.path.indexOf('/Calculate') > -1){
				return true
			}else{
				return false
			}
		}
		, is_on_account: function(){

			if(this.$route.path.indexOf('/MyPage') > -1){
				return true
			}else{
				return false
			}
		}
		, is_payment: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 2)){
				t = true
			}

			return t
		}
		, is_wallet: function(){

			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 1)){
				t = true
			}

			return t
		}
	}
	,methods: {
		toHome: function(){
			this.$bus.$emit('to', { name: 'Index', hash: 'payment'})
		}
		, toPaymentHistory: function(){
			this.$bus.$emit('to', { name: 'PaymentList', hash: 'payment'})
		}
		, toWalletHistory: function(){
			this.$bus.$emit('to', { name: 'WalletHistory'})
		}
		, toAccount: function(){
			this.$bus.$emit('to', { name: 'MyPage', hash: 'payment'})
		}
		, toCalculate: function(){
			this.$bus.$emit('to', { name: 'CalculateList'})
		}
	}
}
</script>

<style>

.bottom .on { background-color: #eee; color: #fefefe;}
.bottom .on i { color: #333 !important;}
</style>